import axios from 'axios';

import Storage from 'storage';

const GET = (url: string) => {
  const userToken = Storage.get('user')?.token;
  const organizationToken = Storage.get('organization')?.token;
  const endpoint = Storage.get('API_ENDPOINT');

  return axios
    .get(endpoint + url, {
      headers: { Token: organizationToken, User: userToken },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return new Promise(function (_, reject) {
        // Handle no response errors. Mainly the generic "Network error"
        if (!error.response) {
          reject({ error: 'No response', message: error });
        }

        // Handle invalid token reponse
        if ([403].includes(error.response.status)) {
          Storage.remove('user');
          Storage.remove('organization');
          window.location.reload();
        }

        // Handle invalid request response. Mainly for invalid form data
        if ([404].includes(error.response.status)) {
          reject(error.response.data);
        }

        // Handle all other unforeseen unhandled errors
        reject({ error: 'Unhandled error', message: error });
      });
    });
};

const DELETE = (url: string) => {
  const userToken = Storage.get('user')?.token;
  const organizationToken = Storage.get('organization')?.token;
  const endpoint = Storage.get('API_ENDPOINT');

  return axios
    .delete(endpoint + url, {
      headers: { Token: organizationToken, User: userToken },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return new Promise(function (_, reject) {
        // Handle no response errors. Mainly the generic "Network error"
        if (!error.response) {
          reject({ error: 'No response', message: error });
        }

        // Handle invalid token reponse
        if ([403].includes(error.response.status)) {
          Storage.remove('user');
          Storage.remove('organization');
          window.location.reload();
        }

        // Handle all other unforeseen unhandled errors
        reject({ error: 'Unhandled error', message: error });
      });
    });
};

const POST = (url: string, data: any) => {
  const userToken = Storage.get('user')?.token;
  const organizationToken = Storage.get('organization')?.token;
  const endpoint = Storage.get('API_ENDPOINT');

  return axios
    .post(endpoint + url, data, {
      headers: { Token: organizationToken, User: userToken },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return new Promise(function (_, reject) {
        // Handle no response errors. Mainly the generic "Network error"
        if (!error.response) {
          reject({ error: 'No response', message: error });
        }

        // Handle invalid token reponse
        if ([403].includes(error.response.status)) {
          Storage.remove('user');
          Storage.remove('organization');
          window.location.reload();
        }

        // Handle invalid request response. Mainly for invalid form data
        if ([400, 401].includes(error.response.status)) {
          reject(error.response.data);
        }

        // Handle all other unforeseen unhandled errors
        reject({ error: 'Unhandled error', message: error });
      });
    });
};

const PATCH = (url: string, data: any) => {
  const userToken = Storage.get('user')?.token;
  const organizationToken = Storage.get('organization')?.token;
  const endpoint = Storage.get('API_ENDPOINT');

  return axios
    .patch(endpoint + url, data, {
      headers: { Token: organizationToken, User: userToken },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return new Promise(function (_, reject) {
        // Handle no response errors. Mainly the generic "Network error"
        if (!error.response) {
          reject({ error: 'No response', message: error });
        }

        // Handle invalid token reponse
        if ([403].includes(error.response.status)) {
          Storage.remove('user');
          Storage.remove('organization');
          window.location.reload();
        }

        // Handle invalid request response. Mainly for invalid form data
        if ([400, 401].includes(error.response.status)) {
          reject(error.response.data);
        }

        // Handle all other unforeseen unhandled errors
        reject({ error: 'Unhandled error', message: error });
      });
    });
};

export { GET, POST, PATCH, DELETE };
