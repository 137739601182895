import { useAppSelector, useAppDispatch } from 'hooks';

import { Text, Box, Flex, Button } from '@chakra-ui/react';

import { DeleteIcon } from '@chakra-ui/icons';

import Card from 'components/card/Card';
import DateField from 'components/fields/DateField';

import FormInput from '../../components/FormInput';

import getDateString from '../../helpers/getDateString';
import getDateFromString from '../../helpers/getDateFromString';

import {
  addConviction,
  changeConvictions,
  removeConviction,
} from 'reducers/submissionFormReducer';

const Convictions = () => {
  const dispatch = useAppDispatch();

  const convictions = useAppSelector(
    (state) => state.submissionForm.data.convictions,
  );

  const onConvictionChange = (event: any, index: number) => {
    const { name, value, checked, type } = event.target;
    const payload = type === 'checkbox' ? checked : value;

    dispatch(changeConvictions({ name, index, value: payload }));
  };

  const onDateChange = (date: Date, name: string, index: number) => {
    dispatch(changeConvictions({ name, index, value: getDateString(date) }));
  };

  const onAddNewConviction = () => {
    dispatch(addConviction());
  };

  const onRemoveConviction = (_: any, index: number) => {
    dispatch(removeConviction(index));
  };

  return (
    <Card mb="20px" id="sectionConvictions">
      <Text fontWeight="bold" fontSize="2xl" mb="20px">
        History of Convictions
      </Text>

      <Text fontSize="xs">
        Give details of all convictions of the applicant and any listed driver
        arising from the operation of any automobile in the last 3 years.
      </Text>

      {convictions.map((conviction, index) => {
        return (
          <Flex gap="20px" mt="20px" key={index}>
            <Box w="10%">
              <FormInput
                label="Driver(No.)"
                name="driver_nr"
                value={conviction.driver_nr}
                onChange={(e) => onConvictionChange(e, index)}
              />
            </Box>

            <Box w="20%">
              <DateField
                label="Date"
                date={getDateFromString(conviction.date)}
                onChange={(date: Date) => onDateChange(date, 'date', index)}
              />
            </Box>

            <FormInput
              label="Details"
              name="details"
              value={conviction.details}
              onChange={(e) => onConvictionChange(e, index)}
            />

            <Button mt="30px" onClick={(e) => onRemoveConviction(e, index)}>
              <DeleteIcon />
            </Button>
          </Flex>
        );
      })}

      <Box mt="20px">
        <Button variant="brand" onClick={onAddNewConviction}>
          Add new
        </Button>
      </Box>
    </Card>
  );
};

export default Convictions;
