import { isLocal } from 'utils/helpers';

export const setTokenCookie = (token: string, user: string) => {
  let token_cookie = `Token=${token}; path=/; max-age=60;`;
  let user_cookie = `User=${user}; path=/; max-age=60;`;

  if (!isLocal()) {
    token_cookie = `${token_cookie} domain=.policypilot.ai;`;
    user_cookie = `${user_cookie} domain=.policypilot.ai;`;
  }

  document.cookie = token_cookie;
  document.cookie = user_cookie;
};
