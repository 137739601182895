const submissionFormMiddleware =
  (storeAPI: any) => (next: any) => (action: any) => {
    const result = next(action);

    if (
      action.type.startsWith('submissionForm/') &&
      action.type !== 'submissionForm/changeIsDataSaved'
    ) {
      storeAPI.dispatch({
        type: 'submissionForm/changeIsDataSaved',
        payload: false,
      });
    }

    return result;
  };

export default submissionFormMiddleware;
