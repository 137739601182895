const CONFIG = {
  ITEMS_PER_PAGE: 50,
  ENDPOINTS: {
    local: 'http://localhost:8000/',
    development: 'https://api.dev.big.policypilot.ai/',
    insureu: 'https://api.insureu.policypilot.ai/',
    'insureu-dev': 'https://api.dev.insureu.policypilot.ai/',
  },
};

export default CONFIG;
