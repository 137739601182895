import Storage from 'storage';

import { User, Organization } from './authReducer.types';

const authReducer = (
  state: { user: User; organization: Organization },
  action: { type: string; payload: any },
) => {
  switch (action.type) {
    case 'set_user':
      Storage.store('user', action.payload);
      Storage.store('organization', action.payload.organizations[0]);
      return {
        user: action.payload,
        organization: action.payload.organizations[0],
      };
    case 'remove_user':
      Storage.remove('user');
      Storage.remove('organization');
      Storage.remove('API_ENDPOINT');
      return { user: null, organization: null };
    default:
      return state;
  }
};

export default authReducer;
