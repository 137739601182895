import { Icon } from '@chakra-ui/react';
import { MdDashboard, MdHome } from 'react-icons/md';

import ValidatedPolicy from 'views/_mypolicy/ValidatedPolicy';
import RiskReport from 'views/_mypolicy/reports/RiskReport';

import UploadPolicyView from 'views/upload_policy';
import SubmissionView from 'views/submission';
import ValidatedPoliciesView from 'views/validated_policies';
import PolicyOverviewView from 'views/policy_overview';
import NewUser from 'views/new_user';
import UserList from 'views/user_list';
import EditUser from 'views/edit_user';

const routes = [
  {
    name: 'Policies',
    path: '/policies',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    collapse: true,
    is_open: true,
    items: [
      {
        name: 'Policies',
        layout: '/admin',
        path: '/policies/history',
        component: <ValidatedPoliciesView />,
      },
      {
        name: 'Analytics',
        layout: '/admin',
        path: '/policies/analytics',
        component: <RiskReport />,
        admin_only: true,
      },
      {
        name: 'Create New Policy',
        layout: '/admin',
        path: '/policies/new',
        component: <UploadPolicyView />,
      },
      {
        name: 'Validate Existing Policy',
        layout: '/admin',
        path: '/policies/existing',
        component: <UploadPolicyView existingPolicy />,
        admin_only: true,
      },
      {
        name: 'Submission',
        layout: '/admin',
        path: '/policies/submission/:uuid',
        component: <SubmissionView />,
        show: false,
      },
      {
        name: 'Validated Policy',
        layout: '/admin',
        path: '/policies/validations/:uuid',
        component: <ValidatedPolicy />,
        show: false,
      },
      {
        name: 'Policy Overview',
        layout: '/admin',
        path: '/policies/overview/:uuid',
        component: <PolicyOverviewView />,
        show: false,
      },
    ],
  },
  {
    name: 'Users',
    path: '/users',
    icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
    collapse: true,
    admin_only: true,
    items: [
      {
        name: 'Manage Users',
        layout: '/admin',
        path: '/users/manage',
        component: <UserList />,
      },
      {
        name: 'New User',
        layout: '/admin',
        path: '/users/new',
        component: <NewUser />,
      },
      {
        name: 'Edit user',
        layout: '/admin',
        path: '/users/manage/:id',
        component: <EditUser />,
        show: false,
      },
    ],
  },
];

export default routes;
