import { useAppSelector, useAppDispatch } from 'hooks';

import { useContext, useState } from 'react';

import { useParams } from 'react-router-dom';

import {
  FormLabel,
  Input,
  useColorModeValue,
  Alert,
  AlertIcon,
  Box,
  Link,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Tooltip,
} from '@chakra-ui/react';

import TextField from 'components/fields/TextField';

import { ApplicationFormContext } from 'contexts/ApplicationFormContext';

import { apiCreateApplicationFile } from 'api';

import getValidationMessage from './getValidationMessage';
import getFailureState from './getFailureState';
import getValidaitonId from './getValidaitonId';

import {
  changeRemarks,
  changeFiles,
  removeFiles,
} from 'reducers/submissionFormReducer';

import { MessageProps } from './Message.types';

const Message = (props: MessageProps) => {
  const { uuid } = useParams();
  const { validations } = useContext(ApplicationFormContext);
  const {
    isOpen: isCompactOpen,
    onOpen: onCompactOpen,
    onClose: onCompactClose,
  } = useDisclosure();
  const dispatch = useAppDispatch();

  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadError, setUploadError] = useState('');

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const key = props?.storageKey
    ? `${props.storageKey}-${props.index}`
    : `global`;
  const validationId = getValidaitonId(props.validationId, validations);

  const remarksValue = useAppSelector(
    (state) =>
      state.submissionForm.remarks?.[`remark-${validationId}`]?.[key] || '',
  );

  const fileName = useAppSelector(
    (state) =>
      state.submissionForm.files?.[`file-${validationId}`]?.[key]?.name || '',
  );

  const message = props?.manual?.show
    ? props?.manual?.message
    : getValidationMessage(validations, validationId, props?.index);

  const failureStatus = !props?.manual?.show
    ? getFailureState(validations, validationId)
    : '';

  const onRemarksChange = (event: any) => {
    const { name, value } = event.target;

    dispatch(changeRemarks({ name, value, key }));
  };

  const onFileChange = (event: any) => {
    setUploadInProgress(true);
    setUploadError('');

    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    formData.append('type', props.file.type as any);

    apiCreateApplicationFile(uuid, formData)
      .then((res) => {
        setUploadInProgress(false);

        dispatch(
          changeFiles({ name: `file-${validationId}`, value: res, key }),
        );
      })
      .catch((error) => {
        setUploadError(error.error);
        setUploadInProgress(false);
      });
  };

  const onFileRemove = () => {
    dispatch(removeFiles({ name: `file-${validationId}`, key }));
  };

  const getCompactAlertStatus = () => {
    if (props?.file && fileName && remarksValue) {
      return 'success';
    }

    if (!props?.file && remarksValue) return 'success';

    return failureStatus === 'caution' || !failureStatus ? 'warning' : 'error';
  };

  if (!message) return null;

  if (props?.compact) {
    return (
      <>
        <Tooltip placement="top" label={message}>
          <Alert status={getCompactAlertStatus()} mt="10px" fontSize="14px">
            <AlertIcon />{' '}
            <Box>
              <Button onClick={() => onCompactOpen()}>Add remark</Button>
            </Box>
          </Alert>
        </Tooltip>
        <Modal isOpen={isCompactOpen} onClose={onCompactClose} size="4xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Remarks details</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box mb="15px">
                <Message {...props} compact={false} />
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  }

  return (
    <>
      <Alert
        status={
          failureStatus === 'caution' || !failureStatus ? 'warning' : 'error'
        }
        mt="10px"
        fontSize="14px"
      >
        <AlertIcon />
        <Box>{message}</Box>
      </Alert>

      {!props.hideRemarks && (
        <Box mt="10px">
          <TextField
            label="Remarks"
            value={remarksValue}
            onChange={onRemarksChange}
            borderColor={!remarksValue ? 'red' : 'inherit'}
            name={`remark-${validationId}`}
            backgroundColor="#fff"
          />
        </Box>
      )}

      {props?.file && (
        <FormLabel
          display="flex"
          ms="10px"
          fontSize="sm"
          color={textColorPrimary}
          fontWeight="bold"
          _hover={{ cursor: 'pointer' }}
          mt="10px"
        >
          {`Upload for "${props.file.text}"`}
        </FormLabel>
      )}

      {props?.file && !uploadInProgress && !fileName && (
        <Box>
          <Input
            type="file"
            p="5px"
            borderColor="red"
            backgroundColor="#fff"
            onChange={onFileChange}
          />
        </Box>
      )}

      {fileName && (
        <Alert status="success">
          <AlertIcon />
          {fileName}
          <Link color="teal.500" onClick={onFileRemove} ml="5px">
            (remove)
          </Link>
        </Alert>
      )}

      {uploadInProgress && (
        <Alert status="info">
          <Box mr="10px">Uploading file...</Box>
          <Spinner />
        </Alert>
      )}

      {uploadError && (
        <Alert status="error" mt="10px">
          <AlertIcon />
          Upload error: {uploadError}
        </Alert>
      )}
    </>
  );
};

export default Message;
