import { useContext } from 'react';

import { useAppSelector, useAppDispatch } from 'hooks';

import {
  Text,
  SimpleGrid,
  Box,
  Flex,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';

import Card from 'components/card/Card';
import DateField from 'components/fields/DateField';

import FormInput from '../../components/FormInput';
import CheckBoxInput from '../../components/CheckBoxInput';
import Message from '../../components/Message';
import UnlistedVehicles from '../../components/UnlistedVehicles';
import Divider from '../../components/Divider';

import { FILE_TYPE } from '../../constants';

import getDateString from '../../helpers/getDateString';
import getDateFromString from '../../helpers/getDateFromString';

import { ApplicationFormContext } from 'contexts/ApplicationFormContext';

import getValidationMessage from '../../components/Message/getValidationMessage';

import {
  changeVehicle,
  changeVehicleSummary,
} from 'reducers/submissionFormReducer';

const Vehicles = () => {
  const { validations } = useContext(ApplicationFormContext) as {
    validations: Validation[];
  };
  const dispatch = useAppDispatch();

  const vehicles = useAppSelector(
    (state) => state.submissionForm.data.vehicles,
  );

  const vehicles_summary = useAppSelector(
    (state) => state.submissionForm.data.vehicles_summary,
  );

  const validation021 = getValidationMessage(validations, '021');
  const validation076 = getValidationMessage(validations, '076');
  const validation037 = getValidationMessage(validations, '037');

  const onVehicleChange = (event: any, index: number) => {
    const { name, value, checked, type } = event.target;
    const payload = type === 'checkbox' ? checked : value;

    dispatch(changeVehicle({ name, index, value: payload }));
  };

  const onVehiclesSummaryChange = (event: any) => {
    const { name, value, checked, type } = event.target;
    const payload = type === 'checkbox' ? checked : value;

    dispatch(changeVehicleSummary({ name, value: payload }));
  };

  const onDateChange = (date: Date, name: string, index: number) => {
    dispatch(changeVehicle({ name, index, value: getDateString(date) }));
  };

  return (
    <Card mb="20px" id="sectionVehicles">
      <Text fontWeight="bold" fontSize="2xl" mb="20px">
        Described Automobile
      </Text>

      {vehicles.map((vehicle, index: number) => {
        return (
          <Box key={index} mb={index !== vehicles.length - 1 ? '20px' : ''}>
            <Divider mb="20px">Automobile {index + 1}</Divider>

            <SimpleGrid columns={6} spacing="20px">
              <FormInput
                label="Model Year"
                name="model_year"
                value={vehicle.model_year}
                onChange={(e) => onVehicleChange(e, index)}
                is_mandatory
              />

              <FormInput
                label="Make or Trade Name"
                name="make"
                value={vehicle.make}
                onChange={(e) => onVehicleChange(e, index)}
                is_mandatory
              />

              <FormInput
                label="Model"
                name="model"
                value={vehicle.model}
                onChange={(e) => onVehicleChange(e, index)}
                is_mandatory
              />

              <FormInput
                label="Body Type"
                name="body_type"
                value={vehicle.body_type}
                onChange={(e) => onVehicleChange(e, index)}
                is_mandatory
              />

              <FormInput
                label="No. of Cylinders or Engine Size"
                name="no_of_cylindersor_engine_size"
                value={vehicle.no_of_cylindersor_engine_size}
                onChange={(e) => onVehicleChange(e, index)}
                is_mandatory
              />

              <FormInput
                label="Gross Vehicle Weight Rating (Kg)"
                name="weight"
                value={vehicle.weight}
                onChange={(e) => onVehicleChange(e, index)}
              />
            </SimpleGrid>

            <Flex mt="20px" gap="20px">
              <Box w="20%"></Box>
              <Box w="10%"></Box>
              <Box w="10%"></Box>
              <Box
                w="40%"
                textAlign="center"
                borderBottom="1px"
                borderColor="#ccc"
                mb="5px"
              >
                Purchased/Leased
              </Box>
            </Flex>

            <Flex gap="20px">
              <Box w="20%">
                <FormInput
                  label="Vehicle Identification No. (Serial No.)"
                  name="vin"
                  value={vehicle.vin}
                  onChange={(e) => onVehicleChange(e, index)}
                  is_mandatory
                />
              </Box>
              <Box w="10%" pt="40px" textAlign="center">
                <CheckBoxInput
                  name="is_owend"
                  isChecked={vehicle.is_owend}
                  onChange={(e) => onVehicleChange(e, index)}
                >
                  Owned?
                </CheckBoxInput>
              </Box>
              <Box w="10%" pt="40px" textAlign="center">
                <CheckBoxInput
                  name="is_leased"
                  isChecked={vehicle.is_leased}
                  onChange={(e) => onVehicleChange(e, index)}
                >
                  Leased?
                </CheckBoxInput>
              </Box>
              <Box w="20%">
                <DateField
                  label="Date"
                  date={getDateFromString(vehicle.purchased_date)}
                  onChange={(date: Date) =>
                    onDateChange(date, 'purchased_date', index)
                  }
                />
              </Box>
              <Box w="10%" pt="40px" textAlign="center">
                <CheckBoxInput
                  name="is_purchased_new"
                  isChecked={vehicle.is_purchased_new}
                  onChange={(e) => onVehicleChange(e, index)}
                >
                  New?
                </CheckBoxInput>
              </Box>
              <Box w="10%" pt="40px" textAlign="center">
                <CheckBoxInput
                  name="is_purchased_used"
                  isChecked={vehicle.is_purchased_used}
                  onChange={(e) => onVehicleChange(e, index)}
                >
                  Used?
                </CheckBoxInput>
              </Box>
              <Box w="10%">
                <FormInput
                  label="Purchase Price"
                  name="purchase_price"
                  value={vehicle.purchase_price}
                  onChange={(e) => onVehicleChange(e, index)}
                  is_mandatory
                />
              </Box>
            </Flex>

            <Flex gap="20px">
              <Box w="20%"></Box>
              <Box w="10%"></Box>
              <Box w="10%"></Box>
              <Box w="40%">
                <Message
                  validationId="040"
                  key="vehicle"
                  index={index + 1}
                  file={FILE_TYPE.TYPE_BILL_OF_SALE}
                  hideRemarks
                />
              </Box>
            </Flex>

            <Box
              mt="20px"
              textAlign="center"
              borderBottom="1px"
              borderColor="#ccc"
              mb="5px"
            >
              Automobile Use
            </Box>

            <SimpleGrid columns={5} spacing="20px">
              <Box pt="40px" textAlign="left">
                <CheckBoxInput
                  name="is_pleasure"
                  isChecked={vehicle.is_pleasure}
                  onChange={(e) => onVehicleChange(e, index)}
                >
                  Pleasure Use
                </CheckBoxInput>
              </Box>
              <FormInput
                label="Commute One - Way"
                name="commute_one_way"
                value={vehicle.commute_one_way}
                onChange={(e) => onVehicleChange(e, index)}
                message={{
                  validationId: '012',
                  storageKey: 'vehicle',
                  index: index + 1,
                  compact: true,
                }}
                is_mandatory
              />
              <FormInput
                label="Business Use %"
                name="business_use_percentage"
                value={vehicle.business_use_percentage}
                onChange={(e) => onVehicleChange(e, index)}
                message={{
                  validationId: '013',
                  storageKey: 'vehicle',
                  index: index + 1,
                  compact: true,
                }}
              />
              <Box pt="40px" textAlign="center">
                <CheckBoxInput
                  name="is_farm"
                  isChecked={vehicle.is_farm}
                  onChange={(e) => onVehicleChange(e, index)}
                >
                  Farm Use
                </CheckBoxInput>
              </Box>
              <Box pt="40px" textAlign="center">
                <CheckBoxInput
                  name="is_commercial"
                  isChecked={vehicle.is_commercial}
                  onChange={(e) => onVehicleChange(e, index)}
                >
                  Commercial Use
                </CheckBoxInput>
              </Box>
            </SimpleGrid>

            <Flex mt="20px" gap="20px">
              <Box w="33%"></Box>
              <Box
                w="35%"
                textAlign="center"
                borderBottom="1px"
                borderColor="#ccc"
                mb="5px"
              >
                Type of Fuel Used
              </Box>
            </Flex>

            <SimpleGrid columns={7} spacing="20px">
              <FormInput
                label="Annual Driving Distance"
                name="estimated_annual_driving_distance"
                value={vehicle.estimated_annual_driving_distance}
                onChange={(e) => onVehicleChange(e, index)}
                message={{
                  validationId: '014',
                  storageKey: 'vehicle',
                  index: index + 1,
                  compact: true,
                }}
                is_mandatory
              />
              <Box pt="25px" textAlign="center">
                <CheckBoxInput
                  name="is_car_pooling"
                  isChecked={vehicle.is_car_pooling}
                  onChange={(e) => onVehicleChange(e, index)}
                  message={{
                    validationId: 'car_pooling',
                    storageKey: 'vehicle',
                    index: index + 1,
                    manual: {
                      message: 'Please provide car pooling details',
                      show: vehicle.is_car_pooling,
                    },
                    compact: true,
                  }}
                >
                  Is any automobile used for car pooling?
                </CheckBoxInput>
              </Box>
              <Box pt="40px" textAlign="center">
                <CheckBoxInput
                  name="is_gas"
                  isChecked={vehicle.is_gas}
                  onChange={(e) => onVehicleChange(e, index)}
                >
                  Gas
                </CheckBoxInput>
              </Box>
              <Box pt="40px" textAlign="center">
                <CheckBoxInput
                  name="is_diesel"
                  isChecked={vehicle.is_diesel}
                  onChange={(e) => onVehicleChange(e, index)}
                >
                  Diesel
                </CheckBoxInput>
              </Box>
              <FormInput
                label="Other"
                name="other_fuel_type"
                value={vehicle.other_fuel_type}
                onChange={(e) => onVehicleChange(e, index)}
                message={{
                  validationId: '016',
                  storageKey: 'vehicle',
                  index: index + 1,
                  manual: {
                    message:
                      'Please provide details about fuel type other than gas or diesel',
                    show: !vehicle.is_diesel && !vehicle.is_gas,
                  },
                  compact: true,
                }}
              />
              <Box pt="40px" textAlign="center">
                <CheckBoxInput
                  name="is_unrepaired_damage"
                  isChecked={vehicle.is_unrepaired_damage}
                  onChange={(e) => onVehicleChange(e, index)}
                  message={{
                    validationId: 'unrepaired_damage',
                    storageKey: 'vehicle',
                    index: index + 1,
                    manual: {
                      message: 'Please provide details about unrepaired damage',
                      show: vehicle.is_unrepaired_damage,
                    },
                    file: FILE_TYPE.TYPE_UNREPAIRED_DAMAGE,
                    compact: true,
                  }}
                >
                  Unrepaired Damage?
                </CheckBoxInput>
              </Box>
              <Box pt="40px" textAlign="center">
                <CheckBoxInput
                  name="is_modified_customized"
                  isChecked={vehicle.is_modified_customized}
                  onChange={(e) => onVehicleChange(e, index)}
                  message={{
                    validationId: 'modified_customized',
                    storageKey: 'vehicle',
                    index: index + 1,
                    manual: {
                      message:
                        'Please provide details about modification and customization',
                      show: vehicle.is_modified_customized,
                    },
                    file: FILE_TYPE.TYPE_MODIFED_CUSTOMIZED,
                    compact: true,
                  }}
                >
                  Modified / Customized
                </CheckBoxInput>
              </Box>
            </SimpleGrid>

            <Box mt="20px">
              <FormInput
                label="Lienholder Name & Postal Address"
                name="lienholder"
                value={vehicle.lienholder}
                onChange={(e) => onVehicleChange(e, index)}
                is_mandatory={vehicle._info.is_lienholder_mandatory}
              />

              {vehicle._info.is_lienholder_mandatory && (
                <Alert status="warning" fontSize="12px" mt="20px">
                  <AlertIcon />
                  <Box>
                    "#23a Mortgage" coverage detected, lienholder information is
                    mandatory.
                  </Box>
                </Alert>
              )}
            </Box>
          </Box>
        );
      })}

      <Divider mt="20px">Summary</Divider>

      <Box mt="20px">
        <CheckBoxInput
          name="is_full_onwer"
          isChecked={vehicles_summary.is_full_onwer}
          onChange={onVehiclesSummaryChange}
          message={{
            validationId: 'registered_vs_actual_owner',
            file: FILE_TYPE.TYPE_COPY_OF_VEHICLE_OWNERSHIP,
            manual: {
              message: 'Please upload a copy of "vehicle ownership"',
              show: !vehicles_summary.is_full_onwer,
            },
            hideRemarks: true,
          }}
        >
          Is the applicant both the Registered Owner and the Actual Owner of the
          described automobile(s)?
        </CheckBoxInput>

        <Box mt="20px">
          <CheckBoxInput
            name="is_additional_risk"
            isChecked={vehicles_summary.is_additional_risk}
            onChange={onVehiclesSummaryChange}
          >
            Will any of the described automobiles be rented or leased to others,
            or used to carry passengers for compensation or hire, or haul a
            trailer, or carry explosives or radioactive material?
          </CheckBoxInput>
        </Box>

        <Box mt="20px" w="50%">
          <FormInput
            label="Total number of automobiles in the household or business. "
            name="total_vehicles"
            value={vehicles_summary.total_vehicles}
            onChange={onVehiclesSummaryChange}
          />
        </Box>
      </Box>

      {validation021 && <UnlistedVehicles />}

      {validation076 && (
        <>
          <Divider mt="20px">Winter tires</Divider>
          <Box mt="10px">
            <Message
              validationId="076"
              file={FILE_TYPE.TYPE_WINTER_TYRE_RECEIPT}
            />
          </Box>
        </>
      )}

      {validation037 && (
        <>
          <Divider mt="20px">DCPD waiver</Divider>
          <Box mt="10px">
            <Message validationId="037" />
          </Box>
        </>
      )}
    </Card>
  );
};

export default Vehicles;
