import { useContext } from 'react';

import { useParams, useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import {
  Box,
  Flex,
  Icon,
  Progress,
  Text,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  SimpleGrid,
  Stack,
  StackDivider,
  Heading,
  Center,
  OrderedList,
  ListItem,
  Link,
} from '@chakra-ui/react';

import {
  ExternalLinkIcon,
  QuestionIcon,
  WarningIcon,
  CheckCircleIcon,
} from '@chakra-ui/icons';

import Card from 'components/card/Card';

import { AuthContext } from 'contexts/AuthContext';

import { apiPolicyOverview } from 'api';

import { setTokenCookie } from 'utils/cookies';

import type { ApiPolicyOverview } from 'types/api.types';

const PolicyOverviewView = () => {
  const { organization, user } = useContext(AuthContext);
  const { uuid } = useParams();
  const navigate = useNavigate();

  const { error, data, isLoading } = useQuery({
    queryKey: ['policy-overview', uuid],
    queryFn: () => apiPolicyOverview(uuid),
  }) as {
    data: ApiPolicyOverview;
    isLoading: boolean;
    error: any;
  };

  const onPDFLinkClick = (url: string) => {
    setTokenCookie(organization.token, user.token);
    window.open(url, '_blank');
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  if (isLoading) {
    return <Card textAlign="center">Loading policy...</Card>;
  }

  if (error) {
    return (
      <Card textAlign="center">
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{JSON.stringify(error)}</AlertDescription>
        </Alert>
      </Card>
    );
  }

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex px="25px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Policy Overview
        </Text>
      </Flex>

      <SimpleGrid mt="20px" columns={2}>
        <Stack divider={<StackDivider />} spacing="4" px="25px">
          <Box>
            <Heading size="xs" textTransform="uppercase">
              Policy No.:
            </Heading>
            <Text pt="2" fontSize="sm">
              {data.metadata.policy_nr}
            </Text>
          </Box>
          <Box>
            <Heading size="xs" textTransform="uppercase">
              Status:
            </Heading>
            <Text pt="2" fontSize="sm">
              <Icon
                w="24px"
                h="24px"
                // ml="5px"
                color={
                  {
                    Approved: 'green.500',
                    Failed: 'red.500',
                    Caution: 'orange.500',
                  }?.[data.metadata.status] || null
                }
                as={
                  {
                    Approved: CheckCircleIcon,
                    Failed: WarningIcon,
                    Caution: QuestionIcon,
                  }?.[data.metadata.status] || null
                }
              />{' '}
              {data.metadata.status}
            </Text>
          </Box>
          <Box>
            <Heading size="xs" textTransform="uppercase">
              Date:
            </Heading>
            <Text pt="2" fontSize="sm">
              {data.date}
            </Text>
          </Box>
          <Box>
            <Heading size="xs" textTransform="uppercase">
              Broker name:
            </Heading>
            <Text pt="2" fontSize="sm">
              {data.metadata.broker}
            </Text>
          </Box>
          <Box>
            <Heading size="xs" textTransform="uppercase">
              Premium:
            </Heading>
            <Text pt="2" fontSize="sm">
              {data.metadata.premium}
            </Text>
          </Box>
          <Box>
            <Heading size="xs" textTransform="uppercase">
              Carrier:
            </Heading>
            <Text pt="2" fontSize="sm">
              {data.metadata.carrier}
            </Text>
          </Box>
        </Stack>

        <Box>
          <Flex>
            <Box w="100%">
              <Stack divider={<StackDivider />} spacing="4">
                <Box>
                  <Heading size="xs" textTransform="uppercase">
                    Underwriting score:
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    <Center w="40px" h="40px" bg="darkgray" color="white">
                      <Box as="span" fontWeight="bold" fontSize="lg">
                        {data.score}
                      </Box>
                    </Center>
                  </Text>
                </Box>
              </Stack>
            </Box>
            <Box w="100%">
              <Stack divider={<StackDivider />} spacing="4" px="25px">
                <Box>
                  <Heading size="xs" textTransform="uppercase">
                    Progress: {data.progress}%
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    <Progress
                      variant="table"
                      colorScheme="brand"
                      h="8px"
                      w="100%"
                      mt="20px"
                      value={data.progress}
                    />
                  </Text>
                </Box>
              </Stack>
            </Box>
          </Flex>

          <Box mt="30px">
            <Stack divider={<StackDivider />} spacing="4" pr="25px">
              <Box>
                <Heading size="xs" textTransform="uppercase">
                  Validations:
                </Heading>
                <Text pt="2" fontSize="sm">
                  <Link
                    color="teal.500"
                    onClick={() =>
                      navigate(`/admin/policies/validations/${uuid}`)
                    }
                  >
                    View policy validations
                  </Link>
                </Text>
              </Box>
            </Stack>
          </Box>

          <Box mt="30px">
            <Stack divider={<StackDivider />} spacing="4" pr="25px">
              <Box>
                <Heading size="xs" textTransform="uppercase">
                  Application submission form:
                </Heading>
                <Text pt="2" fontSize="sm">
                  {data.metadata.policy_nr === 'N/A' && '-'}
                  {data.metadata.policy_nr !== 'N/A' && (
                    <Link
                      color="teal.500"
                      onClick={() =>
                        navigate(`/admin/policies/submission/${uuid}`)
                      }
                    >
                      View application submission form
                    </Link>
                  )}
                </Text>
              </Box>
            </Stack>
          </Box>

          <Box mt="30px">
            <Stack divider={<StackDivider />} spacing="4" pr="25px">
              <Box>
                <Heading size="xs" textTransform="uppercase">
                  Generated PDFs:
                </Heading>
                <Text pt="2" fontSize="sm">
                  {data.application_pdfs.length === 0 && 'No PDF generated yet'}

                  <OrderedList lineHeight="30px">
                    {(
                      data.application_pdfs as ApiPolicyOverview['application_pdfs']
                    ).map((pdf) => (
                      <ListItem>
                        <Link
                          isExternal
                          color="teal.500"
                          onClick={() => onPDFLinkClick(pdf.url)}
                        >
                          Version - {pdf.created_at}{' '}
                          <ExternalLinkIcon mx="2px" />
                        </Link>
                      </ListItem>
                    ))}
                  </OrderedList>
                </Text>
              </Box>
            </Stack>
          </Box>

          <Box mt="30px">
            <Stack divider={<StackDivider />} spacing="4" pr="25px">
              <Box>
                <Heading size="xs" textTransform="uppercase">
                  Attached files:
                </Heading>
                <Text pt="2" fontSize="sm">
                  {data.application_files.length === 0
                    ? 'No files attached'
                    : ''}

                  <OrderedList lineHeight="30px">
                    {data.application_files.map((file) => (
                      <ListItem>
                        <strong>{file.type}:</strong>{' '}
                        <Link href={file.file} isExternal color="teal.500">
                          {file.name} <ExternalLinkIcon mx="2px" />
                        </Link>
                      </ListItem>
                    ))}
                  </OrderedList>
                </Text>
              </Box>
            </Stack>
          </Box>
        </Box>
      </SimpleGrid>
    </Card>
  );
};

export default PolicyOverviewView;
