import { useContext, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from 'hooks';

import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  Box,
  Link,
  Grid,
  GridItem,
  Tooltip,
} from '@chakra-ui/react';

import Card from 'components/card/Card';

import { apiCreateApplicationLog } from 'api';

import { AuthContext } from 'contexts/AuthContext';
import { ApplicationFormContext } from 'contexts/ApplicationFormContext';

import config from 'config';

import { setTokenCookie } from 'utils/cookies';

import { changeIsDataSaved } from 'reducers/submissionFormReducer';

import { isDevEnv } from 'utils/helpers';

const Submit = () => {
  const { uuid } = useParams();
  const { organization, user } = useContext(AuthContext);
  const { validations } = useContext(ApplicationFormContext) as {
    validations: Validation[];
  };
  const dispatch = useAppDispatch();

  const is_additional_risk = useAppSelector(
    (state) => state.submissionForm.data.vehicles_summary.is_additional_risk,
  );
  const data = useAppSelector((state) => state.submissionForm.data);
  const remarks = useAppSelector((state) => state.submissionForm.remarks);
  const files = useAppSelector((state) => state.submissionForm.files);
  const isDataSaved = useAppSelector(
    (state) => state.submissionForm.isDataSaved,
  );

  const hasFailedValidations = useMemo(() => {
    if (!validations) return false;

    return validations.some(
      (validation: any) => validation.validation_outcome === 'fail',
    );
  }, [validations]);

  const onGenerateApplicationLog = (openPDF: boolean) => {
    dispatch(changeIsDataSaved(true));
    apiCreateApplicationLog(uuid, data, remarks, files, openPDF).then(
      (response: any) => {
        if (openPDF) {
          setTokenCookie(organization.token, user.token);
          window.open(response.url, '_blank');
        }
      },
    );
  };

  const shouldDisableSubmit = () => {
    if (isDevEnv()) return false;
    return hasFailedValidations || is_additional_risk;
  };

  return (
    <Card mb="20px">
      {hasFailedValidations && (
        <Alert status="info" mb="20px">
          <AlertIcon />
          <Box>
            <AlertTitle>Failed validations</AlertTitle>
            <AlertDescription>
              Printing application will not be available until all{' '}
              <Link color="teal.500" onClick={() => window.scrollTo(0, 0)}>
                failed validations
              </Link>{' '}
              are resolved.
            </AlertDescription>
          </Box>
        </Alert>
      )}

      {is_additional_risk && (
        <Alert status="info" mb="20px">
          <AlertIcon />
          <Box>
            <AlertTitle>Carries additional risk</AlertTitle>
            <AlertDescription>
              Printing application will not be available if{' '}
              <strong>
                "Will any of the described automobiles be rented or leased to
                others, or used to carry passengers for compensation or hire, or
                haul a trailer, or carry explosives or radioactive material?"
              </strong>{' '}
              is selected
            </AlertDescription>
          </Box>
        </Alert>
      )}

      <Grid templateColumns="repeat(6, 1fr)" gap="20px">
        <GridItem colSpan={1}>
          <Tooltip
            placement="top"
            label="Save form updates without generating PDF"
          >
            <Button
              onClick={() => onGenerateApplicationLog(false)}
              disabled={isDataSaved}
              colorScheme={isDataSaved ? 'brand' : 'yellow'}
              w="100%"
            >
              Save changes
            </Button>
          </Tooltip>
        </GridItem>

        <GridItem colSpan={5}>
          <Button
            onClick={() => onGenerateApplicationLog(true)}
            disabled={shouldDisableSubmit()}
            _hover={{ bg: 'brand.500' }}
            variant="brand"
            w="100%"
          >
            Generate Application PDF
          </Button>
        </GridItem>
      </Grid>
    </Card>
  );
};

export default Submit;
