import { configureStore } from '@reduxjs/toolkit';

import submissionFormReducer from 'reducers/submissionFormReducer';

import submissionFormMiddleware from 'middlewares/submissionFormMiddleware';

const store = configureStore({
  reducer: {
    submissionForm: submissionFormReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(submissionFormMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
