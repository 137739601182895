import config from 'config';
import Storage from 'storage';

export const isLocal = () => {
  return window.location.hostname === 'localhost';
};

export const isDevEnv = () => {
  const endpoint = Storage.get('API_ENDPOINT');

  if (!endpoint) return false;

  return Object.values(config.ENDPOINTS).includes(endpoint);
};
