import { useEffect, useState } from 'react';

import { useMutation } from '@tanstack/react-query';

import Card from 'components/card/Card';

import {
  Flex,
  Text,
  useColorModeValue,
  SimpleGrid,
  Button,
  Box,
  Stack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Spinner,
} from '@chakra-ui/react';

import InputField from 'components/fields/InputField';

import { apiCreateNewUser } from 'api';

const sanitizeFieldName = (name: string) => {
  let newName = name.replace(/_/g, ' ');
  return newName.charAt(0).toUpperCase() + newName.slice(1);
};

const NewUserView = () => {
  const [user, setUser] = useState({
    email: '',
    first_name: '',
    last_name: '',
    password: '',
  });

  const mutation = useMutation({
    mutationFn: apiCreateNewUser,
  });

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const onInputChange = (e: any) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      setUser({
        email: '',
        first_name: '',
        last_name: '',
        password: '',
      });
    }
  }, [mutation.isSuccess]);

  return (
    <Card mb="20px">
      <Flex fontWeight="bold" fontSize="2xl" mb="20px">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Create new user
        </Text>
      </Flex>

      <SimpleGrid columns={2} spacing="20px">
        <SimpleGrid columns={1} spacing="20px">
          <InputField
            name="email"
            label="Email"
            placeholder="example@example.com"
            value={user.email}
            onChange={onInputChange}
          />
          <InputField
            name="first_name"
            label="First name"
            value={user.first_name}
            onChange={onInputChange}
          />
          <InputField
            name="last_name"
            label="Last name"
            value={user.last_name}
            onChange={onInputChange}
          />
          <InputField
            name="password"
            label="Password"
            type="password"
            value={user.password}
            onChange={onInputChange}
          />
          <Button
            onClick={() => mutation.mutate(user)}
            w="30%"
            variant="brand"
            disabled={mutation.isPending}
          >
            {mutation.isPending ? <Spinner /> : 'Create user'}
          </Button>
        </SimpleGrid>

        <Box mt="30px">
          <Stack spacing={3}>
            {mutation.isError && (
              <>
                {Object.entries(
                  mutation.error as unknown as { [key: string]: string[] },
                ).map(([key, messages]) => (
                  <div key={key}>
                    {messages.map((msg) => (
                      <Alert status="warning" key={msg}>
                        <AlertIcon />
                        <Box>
                          <AlertTitle>{sanitizeFieldName(key)}</AlertTitle>
                          <AlertDescription>{msg}</AlertDescription>
                        </Box>
                      </Alert>
                    ))}
                  </div>
                ))}
              </>
            )}
          </Stack>

          {mutation.isSuccess && (
            <Alert status="success">
              <AlertIcon />
              <Box>
                <AlertTitle>Success!</AlertTitle>
                <AlertDescription>
                  User has been added to the organisation
                </AlertDescription>
              </Box>
            </Alert>
          )}
        </Box>
      </SimpleGrid>
    </Card>
  );
};

export default NewUserView;
