import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Link,
  useColorModeValue,
} from '@chakra-ui/react';

import TopNavigationTools from 'components/navbar/TopNavigationTools';

import routes from 'routes';

import { getActiveRoute } from './utils';

const TopNavigation = ({ theme, setTheme }: { [x: string]: any }) => {
  const [pageName, setPageName] = useState(getActiveRoute(routes));
  const location = useLocation();

  const mainText = useColorModeValue('navy.700', 'white');
  const secondaryText = useColorModeValue('gray.700', 'white');

  useEffect(() => {
    setPageName(getActiveRoute(routes));
  }, [location.pathname]);

  return (
    <Box
      position="fixed"
      bg={useColorModeValue('rgba(244, 247, 254, 0.2)', 'rgba(11,20,55,0.5)')}
      backdropFilter="blur(20px)"
      lineHeight="25.6px"
      p="12px"
      top="18px"
      w={{
        base: 'calc(100% - 40px)',
        xl: 'calc(100% - 340px)',
      }}
      right="20px"
    >
      <Flex
        flexDirection={{
          sm: 'column',
          md: 'row',
        }}
        alignItems={{ xl: 'center' }}
      >
        <Box mb={{ sm: '8px', md: '0px' }}>
          <Breadcrumb>
            <BreadcrumbItem color={secondaryText} fontSize="sm" mb="5px">
              <BreadcrumbLink href="#" color={secondaryText}>
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem color={secondaryText} fontSize="sm">
              <BreadcrumbLink href="#" color={secondaryText}>
                {pageName}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>

          <Link color={mainText} href="#" fontWeight="bold" fontSize="34px">
            {pageName}
          </Link>
        </Box>

        <Box ms="auto">
          <TopNavigationTools theme={theme} setTheme={setTheme} />
        </Box>
      </Flex>
    </Box>
  );
};

export default TopNavigation;
