import { useState, useContext, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import {
  Box,
  Flex,
  Icon,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';

import { QuestionIcon, WarningIcon, CheckCircleIcon } from '@chakra-ui/icons';

import { Paginate } from 'react-paginate-chakra-ui';

import Card from 'components/card/Card';

import { AuthContext } from 'contexts/AuthContext';

import { apiPolicyList } from 'api';

import config from 'config';

import type {
  ApiPolicyListData,
  ApiPolicyListResultData,
} from 'types/api.types';

const TABLE_HEADERS = [
  'Policy No.',
  'Status',
  'Date',
  'Broker Name',
  'Premium',
  'Carrier',
  'Underwriting Score',
  'Progress',
];

const ValidatedPoliciesView = () => {
  const { organization } = useContext(AuthContext);
  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const { error, data, isLoading } = useQuery({
    queryKey: ['policy-list', page],
    queryFn: () => apiPolicyList(page + 1),
  }) as {
    data: ApiPolicyListData;
    isLoading: boolean;
    error: any;
  };

  const onPolicyClick = (policy: ApiPolicyListResultData) => {
    navigate(`/admin/policies/overview/${policy.uuid}`);
  };

  if (isLoading) {
    return <Card textAlign="center">Loading policies...</Card>;
  }

  if (error) {
    return (
      <Card textAlign="center">
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{JSON.stringify(error)}</AlertDescription>
        </Alert>
      </Card>
    );
  }

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
      mb="20px"
    >
      <Flex px="20px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          {organization?.is_admin
            ? 'All organization validated policies'
            : 'My validated policies'}
        </Text>
      </Flex>

      <Table variant="simple" color="gray.500" mt="20px">
        <Thead>
          <Tr>
            {TABLE_HEADERS.map((header) => (
              <Th key={header} pe="10px" borderColor={borderColor}>
                <Flex
                  justifyContent="space-between"
                  align="center"
                  fontSize={{ sm: '10px', lg: '12px' }}
                  color="gray.400"
                >
                  {header}
                </Flex>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data.results.map((policy, index) => (
            <Tr
              key={policy.uuid}
              onClick={() => onPolicyClick(policy)}
              cursor="pointer"
            >
              <Td
                key={policy.uuid}
                fontSize={{ sm: '14px' }}
                minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                borderColor="transparent"
              >
                <Flex align="center">
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {policy.metadata.policy_nr !== 'N/A' && '#'}
                    {policy.metadata.policy_nr}
                  </Text>
                </Flex>
              </Td>
              <Td>
                <Flex align="center">
                  <Icon
                    w="24px"
                    h="24px"
                    me="5px"
                    color={
                      {
                        Approved: 'green.500',
                        Failed: 'red.500',
                        Caution: 'orange.500',
                      }?.[policy.metadata.status] || null
                    }
                    as={
                      {
                        Approved: CheckCircleIcon,
                        Failed: WarningIcon,
                        Caution: QuestionIcon,
                      }?.[policy.metadata.status] || null
                    }
                  />
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {policy.metadata.status}
                  </Text>
                </Flex>
              </Td>
              <Td>
                <Flex align="center">
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {policy.date}
                  </Text>
                </Flex>
              </Td>
              <Td>
                <Flex align="center">
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {policy.metadata.broker}
                  </Text>
                </Flex>
              </Td>
              <Td>
                <Flex align="center">
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {policy.metadata.premium}
                  </Text>
                </Flex>
              </Td>
              <Td>
                <Flex align="center">
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {policy.metadata.carrier}
                  </Text>
                </Flex>
              </Td>
              <Td>
                <Flex align="center">
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {policy.score}
                  </Text>
                </Flex>
              </Td>
              <Td>
                <Flex align="center">
                  <Progress
                    variant="table"
                    colorScheme="brand"
                    h="8px"
                    w="108px"
                    value={policy.progress}
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {data.results.length === 0 && (
        <Box textAlign="center" w="100%" mt="20px">
          No policies validated yet
        </Box>
      )}

      {config.ITEMS_PER_PAGE < data.count && (
        <Paginate
          page={page}
          count={data.count}
          pageSize={config.ITEMS_PER_PAGE}
          onPageChange={(page: number) => setPage(page)}
        />
      )}
    </Card>
  );
};

export default ValidatedPoliciesView;
