import { useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import {
  Box,
  Flex,
  Icon,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Select,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  Text,
} from '@chakra-ui/react';

import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';

// @ts-ignore
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import Card from 'components/card/Card';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';

import { MdBarChart, MdAttachMoney } from 'react-icons/md';

import { apiAnalytics } from 'api';

import type { ApiAnalytics } from 'types/api.types';

const gettDateString = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const RiskReport = () => {
  const [selectedDriverDistribution, setSelectedDriverDistribution] =
    useState('All Markets');
  const [selectedVehicleDistribution, setSelectedVehicleDistribution] =
    useState('All Markets');
  const [selectedLocationDistribution, setSelectedLocationDistribution] =
    useState('All Markets');

  const [periodDates, setPeriodDates] = useState({
    startDate: new Date(new Date().getFullYear(), 0, 1),
    endDate: new Date(),
    key: 'selection',
  });
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [analyticsKey, setAnalyticsKey] = useState('initial');

  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

  const { error, data, isFetching } = useQuery({
    queryKey: ['analytics', analyticsKey],
    queryFn: () =>
      apiAnalytics(
        gettDateString(periodDates.startDate),
        gettDateString(periodDates.endDate),
      ),
    refetchOnWindowFocus: false,
  }) as {
    error: any;
    data: ApiAnalytics;
    isFetching: boolean;
  };

  if (isFetching) {
    return <Card textAlign="center">Loading analytics...</Card>;
  }

  if (error) {
    return (
      <Card textAlign="center">
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{JSON.stringify(error)}</AlertDescription>
        </Alert>
      </Card>
    );
  }

  return (
    <>
      <Card mb="20px">
        <Box
          cursor="pointer"
          onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
        >
          <Box w="50%" float="left">
            <Text fontWeight="bold" fontSize="2xl" w="50">
              Period: {gettDateString(periodDates.startDate)} to{' '}
              {gettDateString(periodDates.endDate)}
            </Text>
          </Box>

          <Box float="right">
            {!isDatePickerOpen && <ChevronDownIcon boxSize={8} />}
            {isDatePickerOpen && <ChevronUpIcon boxSize={8} />}
          </Box>
        </Box>
        <Box mt="20px" display={!isDatePickerOpen ? 'none' : ''}>
          <Box mb="20px">
            <hr />
          </Box>
          <DateRangePicker
            onChange={(item: any) => setPeriodDates(item.selection)}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[periodDates]}
            direction="horizontal"
          />
          <Box>
            <Button
              onClick={() => {
                setAnalyticsKey(
                  `${gettDateString(periodDates.startDate)}-${gettDateString(
                    periodDates.endDate,
                  )}`,
                );
                setIsDatePickerOpen(false);
              }}
              colorScheme="brand"
            >
              Apply changes
            </Button>
          </Box>
        </Box>
      </Card>
      <Card w="100%">
        <Flex justifyContent="space-between" gap="20px">
          <Flex gap="20px" wrap={'wrap'}>
            <Box>
              <MiniStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={
                      <Icon
                        w="32px"
                        h="32px"
                        as={MdAttachMoney}
                        color="#6dc5a7"
                      />
                    }
                  />
                }
                name="Gross written premium"
                value={`$${data.gross_written_premium.toLocaleString()}`}
                growth="&nbsp;"
              />
            </Box>

            <Box>
              <MiniStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={
                      <Icon w="32px" h="32px" as={MdBarChart} color="#6dc5a7" />
                    }
                  />
                }
                name="# of Active Policies"
                value={data.active_policies.toLocaleString()}
                growth="&nbsp;"
              />
            </Box>

            <Box>
              <MiniStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={
                      <Icon w="32px" h="32px" as={MdBarChart} color="#6dc5a7" />
                    }
                  />
                }
                name="# of Policies up for Renewal"
                value={data.policies_up_for_renewal.toLocaleString()}
                growth=" "
                growthText="In the Next 30 Days"
                valueColor="#dc4e3a"
              />
            </Box>
          </Flex>
        </Flex>
      </Card>

      <Flex gap="20px" mt="20px" mb="20px">
        <Box w="30%">
          <Card>
            <Box mb="10px" w="100%">
              <Select
                backgroundColor="#fff"
                onChange={(e) => setSelectedDriverDistribution(e.target.value)}
              >
                {Object.entries(data.driver_age_distribution).map(
                  ([key, _]) => {
                    return <option value={key}>{key}</option>;
                  },
                )}
              </Select>
            </Box>
            <Table variant="striped" colorScheme="blackAlpha">
              <TableCaption placement="top">Age Distribution</TableCaption>
              <Thead>
                <Tr>
                  <Th>Age Band</Th>
                  <Th>Percentage (%)</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.driver_age_distribution[selectedDriverDistribution].map(
                  ([age, percentage]) => (
                    <Tr>
                      <Td>{age}</Td>
                      <Td>{percentage}</Td>
                    </Tr>
                  ),
                )}
              </Tbody>
            </Table>
          </Card>
        </Box>

        <Box w="30%">
          <Card>
            <Select
              backgroundColor="#fff"
              onChange={(e) => setSelectedLocationDistribution(e.target.value)}
            >
              {Object.entries(data.location_distribution).map(([key, _]) => {
                return <option value={key}>{key}</option>;
              })}
            </Select>
            <Table variant="striped" colorScheme="blackAlpha">
              <TableCaption placement="top">Location Distribution</TableCaption>
              <Thead>
                <Tr>
                  <Th>City</Th>
                  <Th>Policies</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.location_distribution[selectedLocationDistribution].map(
                  ([age, percentage]) => (
                    <Tr>
                      <Td>{age}</Td>
                      <Td>{percentage}</Td>
                    </Tr>
                  ),
                )}
              </Tbody>
            </Table>
          </Card>
        </Box>

        <Box w="40%">
          <Card>
            <Select
              backgroundColor="#fff"
              onChange={(e) => setSelectedVehicleDistribution(e.target.value)}
            >
              {Object.entries(data.vehicle_age_distribution).map(([key, _]) => {
                return <option value={key}>{key}</option>;
              })}
            </Select>
            <Table variant="striped" colorScheme="blackAlpha">
              <TableCaption placement="top">Car Age Distribution</TableCaption>
              <Thead>
                <Tr>
                  <Th>Car Age Band</Th>
                  <Th>Percentage (%)</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.vehicle_age_distribution[selectedVehicleDistribution].map(
                  ([age, percentage]) => (
                    <Tr>
                      <Td>{age}</Td>
                      <Td>{percentage}</Td>
                    </Tr>
                  ),
                )}
              </Tbody>
            </Table>
          </Card>
        </Box>
      </Flex>
    </>
  );
};

export default RiskReport;
